// @see: https://github.com/mglaman/commerce_demo_decoupled/blob/master/src/pages/Product/graphqlQuery.js

import gql from 'graphql-tag'

// import FragmentCommerceProductDefault from './../fragments/commerceProductMergedTypes/CommerceProductDefault'
// import FragmentCommerceProductVariationDefault from './../fragments/commerceProductMergedTypes/Variation/CommerceProductVariationDefault'
// import FragmentCommerceProductPhone from './../fragments/commerceProductMergedTypes/CommerceProductPhone'
// import FragmentCommerceProductVariationPhone from './../fragments/commerceProductMergedTypes/Variation/CommerceProductVariationPhone'

export default (productId, language) => gql`
{
	commerceProductById (
		id: "${productId}",
		language: ${language},
	)
	{

		... on CommerceProduct {

			... on CommerceProductDefault {

				...commerceProductSharedFields

				body {
					summary
					processed
				}
				
				field_category: fieldCategory {
					entity {
						... on TaxonomyTermKategorieSklep {
							name
						}
					}
				}

				variations {
					entity {
						# ... on CommerceProductVariation {

							...commerceProductVariationSharedFields

							... on CommerceProductVariationDefault {
								
								field_images: fieldImages {
									entity {
										entityUuid
									}
									derivative(style: MAX650X650) {
										url
										width
										height
									}
								}

								attribute_size: attributeSize {
									entity {
										... on CommerceProductAttributeValueSize {
											entityId
											entityLabel
											weight
										}
									}
								}

								# attribute_color: attributeColor {
								# 	entity {
								# 		... on CommerceProductAttributeValueColor {
								# 			entityId
								# 			entityLabel
								# 			weight
								# 		}
								# 	}
								# }

								commerce_stock_always_in_stock: commerceStockAlwaysInStock

								field_stock_level: fieldStockLevel {
									available_stock: availableStock
								}
							}
						# }
					}
				}

			}


			# ... on CommerceProductPhone {

			# 	...commerceProductSharedFields

			# 	body {
			# 		summary
			# 		processed
			# 	}
				
			# 	field_category: fieldCategory {
			# 		entity {
			# 			... on TaxonomyTermKategorieSklep {
			# 				name
			# 			}
			# 		}
			# 	}

			# 	field_brand: fieldBrand
			# 	field_model: fieldModel
			# 	field_model_alias: fieldModelAlias
			# 	field_certification: fieldCertification
			# 	field_spec_sim_card_type: fieldSpecSimCardType
			# 	field_spec_sim_card_number: fieldSpecSimCardNumber
			# 	field_spec_networks: fieldSpecNetworks
			# 	field_spec_operating_system: fieldSpecOperatingSystem

			# 	variations {
			# 		entity {
			# 			# ... on CommerceProductVariation {

			# 				...commerceProductVariationSharedFields

			# 				... on CommerceProductVariationPhone {

			# 					field_images: fieldImages {
			# 						entity {
			# 							entityUuid
			# 						}
			# 						derivative(style: MAX650X650) {
			# 							url
			# 							width
			# 							height
			# 						}
			# 					}

			# 					attribute_internal_storage: attributeInternalStorage {
			# 						entity {
			# 							... on CommerceProductAttributeValueInternalStorage {
			# 								entityId
			# 								entityLabel
			# 								weight
			# 							}
			# 						}
			# 					}

			# 					attribute_color: attributeColor {
			# 						entity {
			# 							... on CommerceProductAttributeValueColor {
			# 								entityId
			# 								entityLabel
			# 								weight
			# 							}
			# 						}
			# 					}

			# 					commerce_stock_always_in_stock: commerceStockAlwaysInStock

			# 					field_stock_level: fieldStockLevel {
			# 						available_stock: availableStock
			# 					}
			# 				}
			# 			# }
			# 		}
			# 	}

			# }

		}
	}
}

fragment commerceProductSharedFields on CommerceProduct {

	# __typename
	# id
	# drupal_id
	# drupal_internal__nid
	# entityType
	entity_bundle: entityBundle
	drupal_internal__product_id: entityId
	# internal {
	#     type
	# }
	langcode {
		value
	}
	title
	path {
		pid
		alias
		langcode
	}
	created

	# field_menu_item: fieldMenuItem {
	#   	entity {
	#     	title
	# 		entityId
	# 		entityLabel
	#   	}
	# }

	# fieldLinkMoodle {
	# 	uri
	# 	url {
	# 		path
	# 		routed
	# 	}
	# 	title
	# }

	# fieldRelatedProducts {
	# 	entity {
	# 		entityLabel
	# 	}
	# }

	# fieldRelatedProducts {
	#   entity {
	#     ... on CommerceProductDefault {
	#         ...ProductRelated # ???
	#     }
	#   }
	# }

}

fragment commerceProductVariationSharedFields on CommerceProductVariation {

	# entityLabel

	id: uuid # JSON:API response id
	entityType # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it
	type {
    	targetId # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it
	}
	
	variation_id: variationId
	entity_bundle: entityBundle
	sku
	title
	price {
		number
		currency_code: currencyCode
		formatted
	}
	resolved_price: resolvedPrice {
		number
		currency_code: currencyCode
		formatted
	}
	calculated_price: calculatedPrice {
		number
		currency_code: currencyCode
		formatted
	}
	is_base: isBase
	# entityRendered # this contains what would Drupal render, the benefit of this field it behaves the same way as Twig frontend!

}

`