import { graphql } from "gatsby"

import FragmentProductRelated from '../commerceProductDefault/product-related'
// import FragmentCommerceProductVariation from './CommerceProductVariation/shared'

export const query = graphql`
    fragment FragmentProductDetails on CommerceProduct {

        id
        drupal_id
        drupal_internal__product_id
        internal {
            type
        }

        langcode
        title
        body {
            summary
            value
        }
        path {
            pid
            alias
            langcode
        }
        created

        # field_link_moodle {
        #     uri
        #     title
        # }

        # field_brand
        # field_model
        # field_model_alias
        # field_certification
        # field_spec_sim_card_type
        # field_spec_sim_card_number
        # field_spec_networks
        # field_spec_operating_system

        relationships {
            bundle: __typename

            field_category {
                ... on taxonomy_term__kategorie_sklep {
                    name
                }
            }



            field_related_products {
                ... on commerce_product__default {
                    # ...FragmentProductRelated

                    title
                    path {
                        pid
                        alias
                        langcode
                    }
                    body {
                        value
                    }
                    created

                    relationships {
                        bundle: __typename

                        variations {
                            ... on commerce_product_variation__default {

                                id: drupal_id # JSON:API response id
                                type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                                variation_id: drupal_internal__variation_id
                                sku
                                title
                                price {
                                    number
                                    currency_code
                                    formatted
                                }
                                resolved_price {
                                    number
                                    currency_code
                                    formatted
                                }
                                calculated_price {
                                    number
                                    currency_code
                                    formatted
                                }
                                relationships {
                                    bundle: __typename

                                    field_images {
                                        localFile {
                                            childImageSharp {
                                                fluid(
                                                    # maxWidth: 340,
                                                    srcSetBreakpoints: [ 1200 ]
                                                ) {
                                                    # ...GatsbyImageSharpFluid_withWebp
                                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                                }
                                                # fluid(maxWidth: 100) {
                                                #     ...GatsbyImageSharpFluid_withWebp
                                                # }
                                                # fixed(width: 400, height: 400) {
                                                #     ...GatsbyImageSharpFixed
                                                # }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
                # ... on commerce_product__phone {
                #     # ...FragmentProductRelated

                #     title
                #     path {
                #         pid
                #         alias
                #         langcode
                #     }
                #     body {
                #         value
                #     }
                #     created

                #     relationships {
                #         bundle: __typename

                #         variations {
                #             ... on commerce_product_variation__phone {

                #                 id: drupal_id # JSON:API response id
                #                 type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                #                 variation_id: drupal_internal__variation_id
                #                 sku
                #                 title
                #                 price {
                #                     number
                #                     currency_code
                #                     formatted
                #                 }
                #                 resolved_price {
                #                     number
                #                     currency_code
                #                     formatted
                #                 }
                #                 calculated_price {
                #                     number
                #                     currency_code
                #                     formatted
                #                 }
                #                 relationships {
                #                     bundle: __typename

                #                     field_images {
                #                         localFile {
                #                             childImageSharp {
                #                                 fluid(maxWidth: 255) {
                #                                     ...GatsbyImageSharpFluid_withWebp
                #                                 }
                #                             }
                #                         }
                #                     }
                #                 }
                #             }
                #         }

                #     }
                # }
            }



            # commerce_product_variation__default {
            # 	sku
            #     price {
            #         number
            #         currency_code
            #     }
            #     ... on commerce_product_variation__default {
            #     }
            # }



            variations {
                ... on commerce_product_variation__default {
                    # ...FragmentCommerceProductVariation

                    id: drupal_id # JSON:API response id
                    type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                    variation_id: drupal_internal__variation_id
                    sku
                    title
                    price {
                        number
                        currency_code
                        formatted
                    }
                    resolved_price {
                        number
                        currency_code
                        formatted
                    }
                    calculated_price {
                        number
                        currency_code
                        formatted
                    }
                    relationships {
                        bundle: __typename

                        field_images {
                            localFile {
                                childImageSharp {
                                    fluid(
                                        # maxWidth: 525,
                                        srcSetBreakpoints: [ 1200 ]
                                    ) {
                                        # ...GatsbyImageSharpFluid_withWebp
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                    # fluid(maxWidth: 100) {
                                    #     ...GatsbyImageSharpFluid_withWebp
                                    # }
                                    # fixed(width: 400, height: 400) {
                                    #     ...GatsbyImageSharpFixed
                                    # }
                                }
                            }
                        }

                        attribute_size {
                            entityId: drupal_internal__attribute_value_id
                            name
                            weight
                        }

                        # attribute_color {
                        #     drupal_internal__attribute_value_id
                        #     name
                        #     weight
                        # }
                    }
                    commerce_stock_always_in_stock
                    field_stock_level {
                        available_stock
                    }
                }

                # ... on commerce_product_variation__phone {
                #     # ...FragmentCommerceProductVariation

                #     id: drupal_id # JSON:API response id
                #     type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                #     variation_id: drupal_internal__variation_id
                #     sku
                #     title
                #     price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     resolved_price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     calculated_price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     relationships {
                #         bundle: __typename

                #         field_images {
                #             localFile {
                #                 childImageSharp {
                #                     fluid(maxWidth: 525) {
                #                         ...GatsbyImageSharpFluid_withWebp
                #                     }
                #                 }
                #             }
                #         }

                #         attribute_internal_storage {
                #             drupal_internal__attribute_value_id
                #             name
                #             weight
                #         }

                #         attribute_color {
                #             drupal_internal__attribute_value_id
                #             name
                #             weight
                #         }
                #     }
                #     commerce_stock_always_in_stock
                #     field_stock_level {
                #         available_stock
                #     }
                # }
            }

        }

    }
`