import React from "react"
//import { Link } from "gatsby"
// import Img from 'gatsby-image'
// import Image from "../../image"

// s4n
// import "./../assets/sass/pages/_blog.scss"
// import axios from "axios"
import { useIntl, Link } from "gatsby-plugin-intl"



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const type = props.type || `as_columns`;

    const reviewColClassBootstrap = type === `as_rows` ? "col-12 mb-5" : "col-lg-4 col-sm-6 mb-5 mb-lg-0";

    return (
        <React.Fragment>
            {/* <!-- testimonial --> */}
            <section className="___section bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="mb-4">What Our Customers Think?</h3>
                        </div>

                        {[1, 2, 3].map(i => {
                            return (
                                <React.Fragment>
                                    {/* <!-- testimonial-item --> */}
                                    <div className={reviewColClassBootstrap}>
                                        <div className="d-flex">
                                            <div>
                                                <img className="rounded-circle mr-4" src="/elite-shop/images/customer/customer-1.jpg" alt="customer-img" />
                                            </div>
                                            <div>
                                                <ul className="list-inline">
                                                    <li className="list-inline-item mx-0"><a href="#" className="rated"><i className="ti-star"></i></a></li>
                                                    <li className="list-inline-item mx-0"><a href="#" className="rated"><i className="ti-star"></i></a></li>
                                                    <li className="list-inline-item mx-0"><a href="#" className="rated"><i className="ti-star"></i></a></li>
                                                    <li className="list-inline-item mx-0"><a href="#" className="rated"><i className="ti-star"></i></a></li>
                                                    <li className="list-inline-item mx-0"><a href="#" className="rated"><i className="ti-star"></i></a></li>
                                                </ul>
                                                <h4 className="text-dark">Best quality for the price</h4>
                                                <p className="text-gray">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremqe laudant tota rem ape riamipsa eaque.</p>
                                                <h5 className="customer-name text-dark">Maggie Scott</h5>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}

                    </div>
                </div>
            </section>
            {/* <!-- /testimonial --> */}
        </React.Fragment>
    )
}

export default ComponentStateless