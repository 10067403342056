/**
 * @see: https://mdbootstrap.com/previews/templates/e-commerce/html/product/product-v1.html
 * 
 * @todo: normalize graphql data taken dynamicly from drupal or taken staticly from gatsby???
 */
import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from 'gatsby-image'
import Image from "./../../components/image"
import SEO from "./../../components/seo"

// s4n
import { useIntl, Link } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
import Preloader from './../../components/s4n/Preloader'
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import { client } from './../../apollo/client'
import graphqlQuery from "./../../model/apollo/product-details"
import ProductBaseInfo from "./../../components/s4n/Shop/Product/base_info"
import ProductSpecification from "./../../components/s4n/Shop/Product/specification"
import ProductReviews from "./../../components/s4n/Shop/Product/reviews"
import ProductRelated from "./../../components/s4n/Shop/Product/related"
import { Accordion, Card, Tabs, Tab } from 'react-bootstrap'
// import MoodleLogo from './../../images/custom/brands/moodle_sitebar_logo_grayhat.svg'
// s4n / shop
import FragmentProductDetails from '../../model/fragments/commerceProductDefault/product-details'
import ProductImage from '../../components/s4n/Shop/Product/image'
import ProductPrice from '../../components/s4n/Shop/Product/price'
import ProductQuantityAddToCart from '../../components/s4n/Shop/Product/Quantity'
import WishlistHandler from '../../components/s4n/Shop/Product/Wishlist'


// shop
import withDrupalOauthConsumer from './../../components/drupal-oauth/withDrupalOauthConsumer';
// import DrupalOauthContext from './../../components/drupal-oauth/DrupalOauthContext';

import { connect } from 'react-redux'
import { cartAdd } from './../../components/s4n/Shop/ext/redux/actions/cart'
import VariationHelper from './../../components/s4n/Shop/ext_old/pages/Product/AddToCart/VariationHelper'
import VariationsAddToCart from './../../components/s4n/Shop/ext_old/pages/Product/AddToCart/variations'
import ImageUtils from './../../utils/gatsby/image';
// import DrupalComments from 'gatsby-plugin-drupal-comments';



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { data } = props;
    const node = data.node; // === const { node } = data;

    // prepare/extract data
    const productId = node.drupal_internal__product_id;
    const drupal_internal__product_id = node.drupal_internal__product_id;
    const languageId = intl.locale;
    const variations = node.relationships.variations ? node.relationships.variations : null;
    const defaultVariation = typeof variations[0] !== `undefined` ? variations[0] : null;
    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    const nodeBodyField = useParseInlineImages(node);
    const imageUtils = ImageUtils.getGatsbyImageData();

    const LOAD_VARIANTS_DYNAMICALLY = true;

    /**
     * @see: https://reactjs.org/docs/hooks-reference.html#functional-updates
     * Unlike the setState method found in class components, useState does not automatically merge update objects. 
     * You can replicate this behavior by combining the function updater form with object spread syntax:
     */
    const [state, setState] = useState({
        loading: LOAD_VARIANTS_DYNAMICALLY,
        error: false,
        variations: LOAD_VARIANTS_DYNAMICALLY ? null : variations,
        defaultVariation: LOAD_VARIANTS_DYNAMICALLY ? null : defaultVariation,
        addToCartQuantity: 1,
    });

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount() we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        LOAD_VARIANTS_DYNAMICALLY && getCommerceProductById(productId, languageId);
    }, []);

    // const resolveDefaultVariationByIdFromGatsbyGraphQl = (variationId) => {
    //     return state.variations.filter((variation, key) => variation.variationId === variationId).shift();
    // }

    const updateDefaultVariation = async (variation) => {
        // console.log(`updateDefaultVariation`)
        // variation = resolveDefaultVariationByIdFromGatsbyGraphQl(variation.variationId);
        // setState(prevState => {
        //     return {
        //         ...prevState, 
        //         defaultVariation: variation,
        //     }
        // });
        setState((prevState, props) => ({
            ...prevState,
            defaultVariation: variation,
        }));
    }

    const getCommerceProductById = async (productId, languageId) => {
        // setState(prevState => {
        //     return {
        //         ...prevState, 
        //         loading: true,
        //     }
        // })
        setState((prevState, props) => ({
            ...prevState,
            loading: true,
        }));

        // @see: https://graphql-search-api.readthedocs.io/en/latest/
        const APOLLO_QUERY = graphqlQuery(productId, languageId.toUpperCase());
// console.log(APOLLO_QUERY.loc.source.body)

        let result = null;
        try {
            result = await client.query({
                query: APOLLO_QUERY,



/**
 * FIX DRUPAL GRAPHQL SPECIFICATION THAT IS NOT RETURNS NULL ON EMPTY FIELD!!!
 * @see: https://github.com/apollographql/apollo-client/issues/4267
 * Filter out empty products ex. { __typename: "CommerceProductTest" } and no other properties!
 */
// fetchPolicy: `cache-first`,
fetchPolicy: `no-cache`,



            }); // {data: {…}, loading: false, networkStatus: 7, stale: false}
            // const { data, loading, error } = await client.query({query: APOLLO_QUERY});
        } catch (error) {
            // setState(prevState => {
            //     return {
            //         ...prevState,
            //         loading: false,
            //         error: error,
            //     }
            // });
            setState((prevState, props) => ({
                ...prevState,
                loading: false,
                error: error,
            }));
        }

// console.log(result);
// return;

// console.log(result.data.commerceProductById.variations)
// return;

        const variationsTransformed = VariationHelper.changeStructureToBeTheSameAsGatsbyGraphQL(result.data.commerceProductById.variations, imageUtils);
        const defaultVariation = variationsTransformed[0];

// console.log(variationsTransformed);
// console.log(defaultVariation);
// return;


        // setState(prevState => {
        //     return {
        //         ...prevState, 
        //         loading: result.loading,
        //         variations: variationsTransformed,
        //         defaultVariation: defaultVariation,
        //     };
        // });
        setState((prevState, props) => ({
            ...prevState,
            loading: result.loading,
            variations: variationsTransformed,
            defaultVariation: defaultVariation,
        }));
    }

    const getMeta = () => {
        // const img = state.defaultVariation && state.defaultVariation.relationships && state.defaultVariation.relationships.field_images && state.defaultVariation.relationships.field_images.length ? 
        //     state.defaultVariation.relationships.field_images[0].localFile.childImageSharp.fluid : null;
        const img = defaultVariation && defaultVariation.relationships && defaultVariation.relationships.field_images && defaultVariation.relationships.field_images.length ? 
            defaultVariation.relationships.field_images[0].localFile.childImageSharp.fluid : null;
// console.log(`img: `, img)

        let meta = [];
        if (img) {
            meta.push(
                {
                    property: `image`,
                    content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
                }, 
                {
                    property: `og:image`,
                    content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
                },
                {
                    property: `twitter:image`,
                    content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
                }
            );
        }

        return meta;
    }

    // if (state.loading || state.defaultVariation === null) {
    //     return null;
    // }

    // if (!state.variations) {
    //     return null;
    // }

    const FormHeuresisProduktZapytanie = loadable(() => import(`./../../components/forms/webform/heuresis_produkt_zapytanie`), {fallback: <Preloader />})

    let aAccordionTabs = [];
    if (false && nodeBodyField) {
        aAccordionTabs = [
            // {header: t({ id: "soft4net_shop_commerce_product_page_accordion_tabs_details" }), content: nodeBodyField},
            {
                id: `description`,
                header: t({ id: `soft4net_shop_commerce_product_page_accordion_tabs_description` }), 
                content: nodeBodyField,
            },
            {
                id: `specification`,
                header: t({ id: `soft4net_shop_commerce_product_page_accordion_tabs_specification` }), 
                content: <ProductSpecification node={node} />,
            },
            {
                id: `accessories`,
                header: t({ id: `soft4net_shop_commerce_product_page_accordion_tabs_accessories` }), 
                content: <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />,
            },
            {
                id: `reviews`,
                header: t({ id: `soft4net_shop_commerce_product_page_accordion_tabs_reviews` }), 
                content: <ProductReviews type="as_rows" />,
                // @see: https://github.com/Marouandev/gatsby-plugin-drupal-comments
                // content:  <DrupalComments
                //     entity_id={state.defaultVariation}
                //     type_comment="comment"
                //     field_name="field_comment"
                //     type_content="commerce_product" 
                //     entity_type="commerce_product_variation"
                //     url_api={`${process.env.SOFT4NET_SOURCE_BASE_URL}/jsonapi/comment/comment`}
                //     login_api={process.env.BASIC_AUTH_USERNAME}
                //     password_api={process.env.BASIC_AUTH_PASSWORD}
                // />
            },
            {
                id: `inquiry`,
                header: t({ id: `soft4net_shop_commerce_product_page_accordion_tabs_inquiry` }), 
                content: <FormHeuresisProduktZapytanie productId={productId} />,
            },
        ];
    }

    const category = node.relationships.field_category ? node.relationships.field_category.name : null;

    const onQuantityModify = (quantity) => {
        setState((prevState, props) => ({
            ...prevState,
            addToCartQuantity: quantity,
        }));
    }

// console.log(state.defaultVariation)
    let isEnabledVariation = false;

    const aProductsPackage = process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(v => Number(v)); // [601, 602]
    const isProductPackage = aProductsPackage.includes(drupal_internal__product_id);

    return (
        <Layout node={node}>

            {(() => {
                // const drupalOauthContext = useContext(DrupalOauthContext);

                // props.updateAuthenticatedUserState();
                // console.log(drupalOauthContext)
                // return (
                //     <React.Fragment>
                //         This was rendered!
                //     </React.Fragment>
                // )

                // console.log(props.userAuthenticated)
            })()}

            {/* <SEO title={node.title} keywords={``} meta={getMeta()} /> */}
            <SEO 
                title={node.title} 
                keywords={`${node.title}`} 
                meta={getMeta()}
                field_metatag={node.field_metatag}
            />

            {/* <div id="quickView" className="quickview">
                <div className="row w-100">
                    <div className="col-lg-6 col-md-6 mb-5 mb-md-0 pl-5 pt-4 pt-lg-0 pl-lg-0">
                        <img src="/elite-shop/images/feature/product.png" alt="product-img" className="img-fluid" />
                    </div>
                    <div className="col-lg-5 col-md-6 text-center text-md-left align-self-center pl-5">
                        <h3 className="mb-lg-2 mb-2">Woven Crop Cami</h3>
                        <span className="mb-lg-4 mb-3 h5">$90.00</span>
                        <p className="mb-lg-4 mb-3 text-gray">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspic atis unde omnis iste natus</p>
                        <form action="#">
                            <select className="form-control w-100 mb-2" name="color">
                                <option value="brown">Brown Color</option>
                                <option value="gray">Gray Color</option>
                                <option value="black">Black Color</option>
                            </select>
                            <select className="form-control w-100 mb-3" name="size">
                                <option value="small">Small Size</option>
                                <option value="medium">Medium Size</option>
                                <option value="large">Large Size</option>
                            </select>
                            <button className="btn btn-primary w-100 mb-lg-4 mb-3">add to cart</button>
                        </form>
                        <ul className="list-inline social-icon-alt">
                            <li className="list-inline-item"><a href="#"><i className="ti-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="ti-twitter-alt"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="ti-vimeo-alt"></i></a></li>
                            <li className="list-inline-item"><a href="#"><i className="ti-google"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div> */}



            {state.loading ? 
                <div className={`col-sm-12`} key={`loading`}>
                    <Preloader paddingY="3" />
                </div>
                :                 
                <React.Fragment>
                    {/* <!-- product-single --> */}
                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className={`col-lg-7 mb-4 mb-lg-0`}>
                                    {/* <!-- product image slider --> */}
                                    <div id="carousel-thumb">
                                        <ProductImage defaultVariation={state.defaultVariation} carousel={true} />

                                        <WishlistHandler defaultVariation={state.defaultVariation} />
                                    </div>
                                    {/* <div className="product-slider">
                                        <div data-image="images/product-single/product-sm-1.jpg">
                                            <img className="img-fluid w-100 image-zoom" src="/elite-shop/images/product-single/product-sm-1.jpg" alt="product-img" data-zoom="images/product-single/product-sm-1.jpg" />
                                        </div>
                                        <div data-image="images/product-single/product-sm-2.jpg">
                                            <img className="img-fluid w-100 image-zoom" src="/elite-shop/images/product-single/product-sm-2.jpg" alt="product-img" data-zoom="images/product-single/product-sm-2.jpg" />
                                        </div>
                                        <div data-image="images/product-single/product-sm-3.jpg">
                                            <img className="img-fluid w-100 image-zoom" src="/elite-shop/images/product-single/product-sm-3.jpg" alt="product-img" data-zoom="images/product-single/product-sm-3.jpg" />
                                        </div>
                                    </div> */}
                                </div>
                                {/* <!-- produt details --> */}
                                <div className={`col-lg-5 mb-100`}>
                                    {/* Call backend via Apollo cient */}
                                    
                                    {
                                    state.loading ? 
                                    // false ? 
                                        <div className={`col-sm-12`} key={`loading`}>
                                            <Preloader paddingY="3" />
                                        </div>
                                        :                                                
                                        <React.Fragment>
                                            {(() => {
                                                isEnabledVariation = VariationHelper.isEnabled(state.defaultVariation, [...state.variations]) // we clone variations but this is not deep clone !!!
                                                // return null;
                                            })()}

                                            {/* <div className={`field--name-title`}>{state.defaultVariation.title}</div> */}
                                            {/* <div className="field--name-field-brand">{productBrand}</div> */}
                                            {/* <div className={`field--name-body`}>
                                                <div dangerouslySetInnerHTML={{__html: bodyValue}}/>
                                            </div> */}

                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <h1>
                                                        {isProductPackage ? 
                                                            <React.Fragment>
                                                                {/* {console.log(state.defaultVariation)} */}
                                                                {node.title}
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                {node.title}<br />
                                                                { t({ id: `soft4net_shop_commerce_product_attribute_size_name` }) }{` - ${state.defaultVariation?.relationships?.attribute_size?.entityLabel} CM` || null}                                                                
                                                            </React.Fragment>
                                                        }
                                                        {/* {state.defaultVariation && 
                                                            state.defaultVariation.label ? ` - ${state.defaultVariation.label}CM` : null} */}
                                                    </h1>
                                                    {/* <h2>{ node.title }</h2> */}
                                                    {/* <h4>{category}</h4> */}
                                                </div>
                                                <div className="col-sm-3 text-right">
                                                    <ProductPrice selectedVariation={state.defaultVariation} printLabel={false} />                                            
                                                </div>
                                            </div>

                                            {false && 
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 d-flex align-items-center">
                                                        {/* Stocks */}
                                                        {isEnabledVariation ?
                                                            <React.Fragment>
                                                                <i className="ti-check-box text-success mr-1"></i>
                                                                <span className="text-success">
                                                                    { t({ id: `soft4net_shop_commerce_product_page_stock_level_in_stock` }) }
                                                                    {` `}
                                                                    {state.defaultVariation.field_stock_level.available_stock > 0 ?
                                                                        `, ${t({ id: `soft4net_shop_commerce_product_page_stock_level_available_stock` })} ${state.defaultVariation.field_stock_level.available_stock}` : null
                                                                    }
                                                                    
                                                                </span>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <i className="ti-check-box text-danger"></i>{` `}
                                                                <span className="text-danger">{ t({ id: `soft4net_shop_commerce_product_page_stock_level_out_stock` }) }</span>    
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="col-sm-6 text-right     variants">
                                                        {/* Variants */}
                                                        {state.variations.length > 0 && 
                                                            <VariationsAddToCart isProductPackage={isProductPackage} updateDefaultVariation={updateDefaultVariation} variations={[...state.variations]} />
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            <div className="row product-description">
                                                <div className="col">
                                                    {nodeBodyField}
                                                </div>
                                                {/* <div className="col">
                                                    <ProductBaseInfo node={node} />
                                                </div> */}
                                            </div>
                                            <div className="row quantity-add-co-cart">
                                                <div className="col-sm-6">
                                                    <ProductQuantityAddToCart printLabel={true} quantity={state.addToCartQuantity} onQuantityModify={onQuantityModify} />
                                                </div>
                                                <div className="col-sm-6">
        {/* {console.log(state.variations)} */}
                                                    {/* Add to cart button */}
                                                    {isEnabledVariation && 
                                                        <React.Fragment>
                                                            <div className="col-12">
                                                                <button onClick={() => props.onAddToCart(state.defaultVariation, state.addToCartQuantity)} className="btn btn-success add-to-cart">
                                                                    { t({ id: `soft4net_shop_commerce_product_page_add_to_cart` }) }
                                                                </button>
                                                                {!isProductPackage && 
                                                                    <p className={`product-package-info`}><Link to={`/wianki/opakowania-ozdobne`}>Wybierz opakowanie ozdobne</Link></p>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>

                                            

                                            {/* <div className={`field--name-field-product-categories`}>
                                                {productCategories.map(category => (
                                                <div key={category.entity.name}>{category.entity.name}</div>
                                                ))}
                                            </div> */}

                                            {/* <div className={`field--name-field-special-categories`}>
                                                {specialCategories.map(category => (
                                                <div key={category.entity.name}>{category.entity.name}</div>
                                                ))}
                                            </div> */}

                                        </React.Fragment>
                                    }



                                    {/* <!-- syo-timer --> */}
                                    {/* <h4 className="mb-3"><span className="text-primary">Harry up!</span> Sale ends in</h4>
                                    <div className="syotimer dark mt-3">
                                        <div id="sale-timer" data-year="2020" data-month="5" data-day="25" data-hour="14"></div>
                                    </div> */}

                                </div>
                                <div className="col-lg-12">
                                    <div className={`tabs product-details-tabs`}>
                                        {!!aAccordionTabs.length && 
                                            <Tabs defaultActiveKey={0}>
                                                {aAccordionTabs.map((tab, index) => {
                                                    return (
                                                        <Tab eventKey={index} id={tab.id} tabClassName={tab.id} title={tab.header}>
                                                            <div className={`tab-pane-wrapper-${tab.id}`}>
                                                                {tab.content}
                                                            </div>
                                                        </Tab>
                                                    )
                                                })}
                                            </Tabs>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col pt-5">
                                    <button onClick={() => {window.history.back();}} className="btn btn-secondary back mb-3" role="button">{ t({ id: `soft4net_shop_commerce_product_page_back_to_shop` }) }</button>
                                </div>
                            </div> */}

                        </div>
                    </section>
                    {/* <!-- /product-single --> */}
                </React.Fragment>   
            }



            {/* <small><em>{ node.created }</em></small> */}
            {/* { nodeBodyField } */}
            {/* <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div> */}

        </Layout>
    )
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onAddToCart: (activeVariation, quantity) => dispatch(cartAdd(activeVariation, quantity)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentStateless);



// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
export const query = graphql`
    query(
        $id: String!,
        $drupal_id: String!,
        $langcode: String!
    ) {
        node: commerceProduct(
            id: {
                eq: $id
            },
            drupal_id: {
                eq: $drupal_id
            },
            langcode: {
                eq: $langcode
            }
        ) {
            ...FragmentProductDetails
        }
    }
`