/**
 * With this Component we reset parent Componenet state.defaultVariation!!!
 * 
 * @todo: Alwayts return cheapest vriation for a given attributes combination
 * @see: https://reactjs.org/docs/forms.html#the-select-tag
 */
import React from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import VariationHelper from './VariationHelper'
// import { DisplayFormikState } from './../../../../../../forms/formikHelper';



class Variations extends React.PureComponent {
  static propTypes = {
    variations: PropTypes.array.isRequired,
  }

  constructor (props) {
    super(props);

    const { intl } = props;
    const t = intl.formatMessage;

    this.t = t; // this.t = this.t.bind(this);
    this.locale = intl.locale;

    this.onChange = this.onChange.bind(this);
    const { variations } = props;

    // Now when we created buckets for base variations we can iterate all to match them and put them into proper buckets, if there's no match variant is now allowed!!!
    // Base variation (with specific attributes combination that are allowed) is created by administrator
    this.variations = VariationHelper.filterAndPrepare(variations);

// console.log(this.variations)

    const defaultVariation = this.variations.length === 0 ? null : this.variations[0];  

// console.log(defaultVariation)

    this.state = {
      activeVariation: defaultVariation,
    }

    // Update initial variation after variations processing
    this.props.updateDefaultVariation(defaultVariation);
  }

  getResolvedVariation(variation_id) {
    return this.variations.filter((variation, key) => variation.variation_id === variation_id).shift();
  }

  // onChange={event => this.setState({ [event.target.name]: event.target.value })}
  onChange({ target: { name, value } }) {
    const variation_id = Number(value);
    const resolvedVariation = this.getResolvedVariation(variation_id);

    this.setState({
      activeVariation: resolvedVariation
    })

    this.props.updateDefaultVariation(resolvedVariation);
  }

  render() {
    const t = this.t;

    // validation if attribute value for variation is not set in admin
    // if (_.isEmpty(this.variations)) {
    //     return null;
    // }

    if (!this.state.activeVariation) {
        return null;
    }

    return (
      <React.Fragment>
        <div className={`field--name-variations w-100`}>
          <div className={`attribute-widgets form-group`}>
            <div className={`field--item form-type-select`}>
                <div className="panel-title form-required">
                  { t({ id: `soft4net_shop_commerce_product_page_variations` }) }
                </div>
                <div className="select-wrapper">
                  <select name={`variationList`} className={`custom-select`} onChange={this.onChange} value={this.state.activeVariation.variation_id}>
                    {this.variations.map((variation, key) => {
                      return (
                        this.props.isProductPackage ? 
                          <option value={variation.variation_id} key={key}>{variation.sku}</option> 
                          : 
                          <option value={variation.variation_id} key={key}>{variation.label}</option>
                      )
                    })}
                  </select>
                </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(Variations);
